import React from "react";

import "./index.scss";

const BreakZone = ({ heading, color }) => {
  return (
    <div className={`zone zone--${color}`}>
      <div className="zone__heading">
        <h3 className="heading-3">{heading}</h3>
      </div>
    </div>
  );
};

export default BreakZone;
