import { createContext, useState } from "react";

export const SidebarContext = createContext({
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
});

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const value = { isSidebarOpen, setIsSidebarOpen };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
