import { useContext } from "react";

import { sidebarItems } from "../../constants/sidebarItems";
import { socialItems } from "../../constants/socialMediaItems";
import { SidebarContext } from "../../context/sidebar.context";
import { IoMdClose } from "../react-icons";

import { Overlay } from "../index";

import "./index.scss";

const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);

  const closeSidebarHandler = () => setIsSidebarOpen(false);

  return (
    <>
      <div className={isSidebarOpen ? "sidebar sidebar--active" : "sidebar"}>
        <IoMdClose className="sidebar__close" onClick={closeSidebarHandler} />
        <ul className="sidebar__links">
          {sidebarItems.map(({ title, hashTag }) => (
            <li className="sidebar__item" key={title}>
              <a
                href={hashTag}
                className="sidebar__link"
                onClick={closeSidebarHandler}
              >
                {title}
              </a>
            </li>
          ))}
        </ul>
        <ul className="sidebar__medias">
          {socialItems.map(({ title, url, icon }) => (
            <li className="sidebar__media" key={title}>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="sidebar__media-link"
              >
                {icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <Overlay showup={isSidebarOpen} setShowup={setIsSidebarOpen} />
    </>
  );
};

export default Sidebar;
