import "./App.css";

import {
  Footer,
  GetInTouch,
  Hero,
  Introduction,
  MobileNav,
  Portfolio,
  Sidebar,
} from "./components/index";

const App = () => {
  return (
    <>
      <MobileNav />
      <Sidebar />
      <Hero />
      <Introduction />
      <Portfolio />
      <GetInTouch />
      <Footer />
    </>
  );
};

export default App;
