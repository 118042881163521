import React from "react";
import { useState } from "react";

import "./index.scss";

const MobileNav = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navOpenHandler = () => setIsNavOpen(!isNavOpen);
  const navCloseHandler = () => setIsNavOpen(false);

  return (
    <div className="mobileNav">
      <div className="mobileNav__button" onClick={navOpenHandler}>
        <span
          className={
            isNavOpen ? "mobileNav__icon btnClicked" : "mobileNav__icon"
          }
        >
          &nbsp;
        </span>
      </div>
      <div
        className={
          isNavOpen
            ? "mobileNav__background background--active"
            : "mobileNav__background"
        }
      >
        &nbsp;
      </div>
      <nav className={isNavOpen ? "mobileNav__nav navOpen" : "mobileNav__nav"}>
        <ul className="mobileNav__list">
          <li className="mobileNav__item">
            <a href="#/" className="mobileNav__link" onClick={navCloseHandler}>
              Home
            </a>
          </li>
          <li className="mobileNav__item">
            <a
              href="#about"
              className="mobileNav__link"
              onClick={navCloseHandler}
            >
              About Me
            </a>
          </li>
          <li className="mobileNav__item">
            <a
              href="#portfolio"
              className="mobileNav__link"
              onClick={navCloseHandler}
            >
              Portfolio
            </a>
          </li>

          <li className="mobileNav__item">
            <a
              href="#git"
              className="mobileNav__link"
              onClick={navCloseHandler}
            >
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MobileNav;
