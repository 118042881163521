import React from "react";

import { sidebarItems } from "../../constants/sidebarItems";
import { socialItems } from "../../constants/socialMediaItems";

import "./index.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__cols container">
        <div className="footer__brand-col">
          <h4 className="footer__heading">
            L<span>.</span>S
          </h4>
        </div>
        <ul className="footer__menu-col">
          {sidebarItems.map(({ title, hashTag }) => (
            <li className="footer__menu-item" key={title}>
              <a
                href={hashTag}
                target="_blank"
                rel="noreferrer"
                className="footer__link"
              >
                {title}
              </a>
            </li>
          ))}
        </ul>
        <ul className="footer__media-col">
          {socialItems.map(({ title, url, icon, slogan }) => (
            <li className="footer__media" key={title}>
              <a href={url} className="footer__media-link">
                {icon}
              </a>
              <span className="footer__media-text">{slogan}</span>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
