import { BsLinkedin, FiGithub, BsBehance } from "../components/react-icons";

export const socialItems = [
  {
    title: "linkin",
    url: "https://www.linkedin.com/in/shizijian/",
    icon: <BsLinkedin />,
    slogan: "My Career!",
  },
  {
    title: "behance",
    url: "https://www.behance.net/lionc",
    icon: <BsBehance />,
    slogan: "What I See!",
  },
  {
    title: "github",
    url: "https://github.com/LIONSHI01",
    icon: <FiGithub />,
    slogan: "Read My Code!",
  },
];
