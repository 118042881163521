import React from "react";

import { Navbar } from "../index";
import "./index.scss";

const Hero = () => {
  return (
    <>
      <header className="header">
        <section className="section-hero" id="hero">
          <div className="hero">
            <Navbar />
            <div className="hero__box">
              <h1 className="hero__name">Lion Shi</h1>
              <h3 className="hero__title">Web Developer</h3>
            </div>
          </div>
        </section>
      </header>
    </>
  );
};

export default Hero;
