import React from "react";

import "./index.scss";
import { selfie, ResumePDF } from "../../constants/resourceExport";
import { SELF_INTRO } from "../../constants/IntroParagraph";

const Introduction = () => {
  return (
    <section className="section-about" id="about">
      <div className="about">
        <div className="details">
          <div className="details__career">
            <h2 className="details__name">Lion Shi</h2>
            <p className="details__title">Web Developer</p>
          </div>
          <h3 className="details__quote">passionate about web development</h3>
          {SELF_INTRO.map((paragraph) => (
            <p className="details__description">{paragraph}</p>
          ))}
        </div>
        <div className="info">
          <div className="info__img-box">
            <img src={selfie} alt="" className="info__image" />
          </div>
          <ul className="info__col">
            <li className="info__item">
              <span>Phone:</span>&nbsp;+852 6202 2503
            </li>
            <li className="info__item">
              <span>Email:</span>&nbsp;lionshi2012@gmail.com
            </li>
            <li className="info__item">
              <span>Website:</span>&nbsp;lionshi.io
            </li>
          </ul>
          <div className="info__cta">
            <a href="#portfolio" className="info__btn">
              My Portfolio
            </a>
            <a href={ResumePDF} className="info__btn" download>
              download my cv
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
