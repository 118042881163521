import React, { useState } from "react";

import { Overlay, Button } from "../index";
import { ImArrowRight2, GrClose, AiFillStar, FiGithub } from "../react-icons";
import "./index.scss";

const Tag = ({ tag }) => {
  return (
    <div className="tag">
      <p className="tag__name">{tag}</p>
    </div>
  );
};

const ProjectModal = ({ showup, setShowup, project }) => {
  const {
    name,
    description,
    photo,
    tags,
    url,
    techStacks,
    features,
    githubUrl,
  } = project;

  const [desc, setDesc] = useState([description?.[0].slice(0, 200)]);
  const [isReadMore, setIsReadMore] = useState(false);
  const [featureItems, setFeatureItems] = useState(features?.slice(0, 5));
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  const onReadMoreHandler = () => {
    if (!isReadMore) {
      setDesc(description);
      setIsReadMore(true);
    } else {
      setDesc([description[0].slice(0, 200)]);
      setIsReadMore(false);
    }
  };
  const onShowAllFeatureHandler = () => {
    if (!showAllFeatures) {
      setFeatureItems(features);
      setShowAllFeatures(true);
    } else {
      setFeatureItems(features.slice(0, 5));
      setShowAllFeatures(false);
    }
  };

  return (
    <>
      <div className={showup ? "projectModal active" : "projectModal"}>
        <div className="projectModal__top">
          <button
            className="projectModal__closeBtn"
            onClick={() => setShowup(false)}
          >
            <GrClose size={20} />
          </button>
        </div>
        <div className="projectModal__main">
          <div className="projectModal__left">
            <span className="projectModal__title">Project: {name}</span>
            <div className="projectModal__photoContainer">
              <img className="projectModal__photo" src={photo} alt={name} />
            </div>
            <div className="stacks">
              <span className="stacks__header">Tech stacks :</span>
              <div className="stacks__list">
                {techStacks.map(({ stack, icon }) => (
                  <div className="stacks__stackItem" key={stack}>
                    <img
                      className="stacks__stacksIcon"
                      src={icon}
                      alt={stack}
                    />
                    <span className="stacks__name">{stack}</span>
                  </div>
                ))}
              </div>
            </div>
            {featureItems && (
              <div className="features">
                <div className="features__header">
                  <span className="features__headerText">App Features :</span>
                  {features.length > 5 && (
                    <span
                      className="features__showall"
                      onClick={onShowAllFeatureHandler}
                    >
                      {showAllFeatures ? " Show less" : " Show all"}
                    </span>
                  )}
                </div>
                <ul className="features__list">
                  {featureItems.map((feature) => (
                    <li className="features__item">
                      <AiFillStar />
                      <span>{feature.item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="projectModal__right">
            <h3>Introduction</h3>

            {desc.map((paragraph, i) => (
              <p className="projectModal__introduction" key={i}>
                {paragraph} {!isReadMore && " ..."}
              </p>
            ))}

            <span
              className="projectModal__readMore"
              onClick={onReadMoreHandler}
            >
              {isReadMore ? " Show less" : " Read more"}
            </span>

            <div className="projectModal__tags">
              {tags.map((tag, i) => (
                <Tag key={i} tag={tag} />
              ))}
            </div>
          </div>
        </div>
        <div className="projectModal__footer">
          <div className="projectModal__btnContainer">
            <Button isLink={true} url={githubUrl}>
              <FiGithub size={25} />
              &nbsp; &nbsp;View on Github
            </Button>
            <Button isLink={true} url={url}>
              Visit Website&nbsp;&nbsp;
              <ImArrowRight2 size={25} />
            </Button>
          </div>
        </div>
      </div>
      <Overlay showup={showup} setShowup={setShowup} />
    </>
  );
};

export default ProjectModal;
