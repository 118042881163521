import {
  utopiaThumbnail,
  veetureThumbnail,
  catforestThumbnail,
  linkifyThumbnail,
  nexterThumbnail,
  trillioThumbnail,
  omnifoodThumbnail,
  pinlandThumbnail,
  toysoryThumbnail,
  pronafThumbnail,
  reactIcon,
  nextIcon,
  nodeIcon,
  expressIcon,
  scssIcon,
  awsIcon,
  mongoDbIcon,
  htmlIcon,
  cssIcon,
  parcelIcon,
  reduxIcon,
  web3Icon,
  reactNativeIcon,
} from "./resourceExport";

export const projects = [
  {
    name: "Utopia",
    url: "https://utopia.lionshi.io/",
    description: [
      "Project Utopia is a experimental second hand trading platform, which is built with Next.js framework for the frontend part and Node.js for the backend part. It allows users to buy goods or list their goods on the platform. ",
      "With the power of Block chain technology, transactions will be settled  on Ethereum Goerli Testnet in seconds. Users can login with email and password or with Ethereum wallet(eg.MetaMask), which is more convenient and secure.",
      " All user data are store in MongoDB and image assets are stored in AWS.",
      "To learn more about it, please visit the project page below.",
    ],
    thumbnail: utopiaThumbnail,
    photo: utopiaThumbnail,
    highlight: true,
    type: "block chain second-hand marketplace",
    tags: ["Block chain", "e-Commerce", "RESTful API"],
    githubUrl: "https://github.com/LIONSHI01/utopia-client",
    techStacks: [
      {
        stack: "react",
        icon: reactIcon,
      },
      {
        stack: "next",
        icon: nextIcon,
      },

      {
        stack: "node",
        icon: nodeIcon,
      },
      {
        stack: "express",
        icon: expressIcon,
      },
      {
        stack: "web3.js",
        icon: web3Icon,
      },

      {
        stack: "mongoDb",
        icon: mongoDbIcon,
      },
      {
        stack: "aws",
        icon: awsIcon,
      },
      {
        stack: "redux",
        icon: reduxIcon,
      },
    ],
    features: [
      {
        item: "Authentication (Signup, Login, Reset password)",
      },
      {
        item: "Product post management (Upload/Edit post details)",
      },
      {
        item: "Real time update products, ethereum price",
      },
      {
        item: "User profile (Upload/Edit Profile Picture, Address, Social media link)",
      },
      {
        item: "Payment method: Ethereum transaction",
      },
      {
        item: "Place an order, Manage an order/offer",
      },
      {
        item: "Create/edit wishlist, add items to wishlist",
      },
      {
        item: "Push notification to user whenever order status",
      },
      {
        item: "Send email for password reset",
      },
      {
        item: "Review, Comment, Rating Product",
      },
      {
        item: "UI Mode Switch: Light mode / Dark mode",
      },
    ],
  },
  {
    name: "Veeture",
    url: "https://veeture.lionshi.io/",
    description: [
      "Project Veeture is a online clothing store, its frontend is built with Next.js framework and leveraging Content management system Sanity to export product contents to the frontend.",
      "The website support user registeration with email or google login, product filtering, online payment and other fundermental functions a e-commerce website should have.",
      "To learn more about it, please visit the project page below.",
    ],
    thumbnail: veetureThumbnail,
    photo: veetureThumbnail,
    type: "e-Commerce Website",
    tags: ["e-Commerce", "CMS"],
    githubUrl: "https://github.com/LIONSHI01/veeture-ecommerce",
    techStacks: [
      {
        stack: "react",
        icon: reactIcon,
      },
      {
        stack: "next",
        icon: nextIcon,
      },
      {
        stack: "redux",
        icon: reduxIcon,
      },
    ],
    features: [
      {
        item: "Authentication (Signup, Login)",
      },
      {
        item: "Admin role management (Upload / Edit product details)",
      },
      {
        item: "Real time update products",
      },
      {
        item: "Payment method: Stripe",
      },
      {
        item: "Place an order, add items to wishlist",
      },
    ],
  },
  {
    name: "Pronaf App",
    url: "https://pronafpage.lionshi.io/",
    description: [
      "Project Pronaf is a NFT marketplace app built with React Native, which is fully compatible to IOS and Android. It allows user search NFT items and view NFT details (including NFT description, bids from other user) on Mobile App. It will also allow user bid on NFT (Coming soon).",
      "To learn more about it, please visit the project page below.",
    ],
    thumbnail: pronafThumbnail,
    photo: pronafThumbnail,
    type: "NFT Marketplace",
    tags: ["Mobile App", "NFT"],
    githubUrl: "https://github.com/LIONSHI01/pronaf-app",
    techStacks: [
      {
        stack: "react native",
        icon: reactNativeIcon,
      },
    ],
    // features: [
    //   {
    //     item: "Authentication (Google login)",
    //   },
    //   {
    //     item: "Upload images and create post",
    //   },
    //   {
    //     item: "Save posts in bookmark",
    //   },
    // ],
  },
  {
    name: "Toysory",
    url: "https://toysory.lionshi.io/",
    description: [
      "Project Toysory is a online toy store, its frontend is built with Next.js framework and leveraging Content management system Sanity to export product contents to the frontend. Using Redux as client-side state management, user can add/remove items into cart.",
      "The website support user registeration with email, product filtering, online payment with Stripe and other fundermental functions a e-commerce website should have.",
      "To learn more about it, please visit the project page below.",
    ],
    thumbnail: toysoryThumbnail,
    photo: toysoryThumbnail,
    type: "Toy e-commerce",
    tags: ["e-commerce", "Content Management System"],
    githubUrl: "https://github.com/LIONSHI01/toysory-app",
    techStacks: [
      {
        stack: "react",
        icon: reactIcon,
      },
      {
        stack: "next",
        icon: nextIcon,
      },
      {
        stack: "sass",
        icon: scssIcon,
      },
      {
        stack: "redux",
        icon: reduxIcon,
      },
      {
        stack: "mongoDB",
        icon: mongoDbIcon,
      },
    ],
    features: [
      {
        item: "Authentication (Signup, Login)",
      },
      {
        item: "Admin role management (Upload / Edit product details)",
      },
      {
        item: "Real time update products",
      },
      {
        item: "Payment method: Stripe",
      },
      {
        item: "In-app search products",
      },
      {
        item: "Place an order, add items to wishlist",
      },
    ],
  },
  {
    name: "pinland",
    url: "https://pinland.lionshi.io/",
    description: [
      "Project Pinland is a Pinterest cloning app. It allows user to login with gmail account without creating extra account. In order to achieve social sharing, user can upload image posts onto website,download images from post they love, comment on others posts, save posts and other users profile into bookmark list.",
      "To learn more about it, please visit the project page below.",
    ],
    thumbnail: pinlandThumbnail,
    photo: pinlandThumbnail,
    type: "Social Sharing App",
    tags: ["Web application", "RESTful API", "CMS"],
    githubUrl: "https://github.com/LIONSHI01/pinland-app-client",
    techStacks: [
      {
        stack: "react",
        icon: reactIcon,
      },
      {
        stack: "css",
        icon: cssIcon,
      },
      {
        stack: "html",
        icon: htmlIcon,
      },
    ],
    features: [
      {
        item: "Authentication (Google login)",
      },
      {
        item: "Upload images and create post",
      },
      {
        item: "Save posts in bookmark",
      },
    ],
  },
  {
    name: "Omnifood",
    url: "https://omnifood.lionshi.io/",
    description: [
      "Project Omnifood is a landing page of a online health food delivery application. It is composed of Section Hero, Features, Application display, Meals introduction, Testimonials, Pricing and Call-For-Action. The page is fully responsive on desktop and mobile devices. ",
      "To learn more about it, please visit the project page below.",
    ],
    thumbnail: omnifoodThumbnail,
    photo: omnifoodThumbnail,
    type: "Landing Page",
    tags: ["Landing page"],
    githubUrl: "https://github.com/LIONSHI01/omifood-responsive",
    techStacks: [
      {
        stack: "html",
        icon: htmlIcon,
      },
      {
        stack: "css",
        icon: cssIcon,
      },
    ],
    // features: [
    //   {
    //     item: "",
    //   },
    // ],
  },
  // {
  //   name: "Linkify",
  //   url: "https://linkify.lionshi.io/",
  //   description: [
  //     "Project Linkify is an API consuming application page for searching food recipes. It allow users to search over 1,000,000 recipes, boomark recipes as favorites and even create new recipe and upload to database.",
  //     "To learn more about it, please visit the project page below.",
  //   ],
  //   thumbnail: linkifyThumbnail,
  //   photo: linkifyThumbnail,
  //   type: "Recipe Searching App",
  //   tags: ["Web Application"],
  //   githubUrl: "https://github.com/LIONSHI01/linkify-app",
  //   techStacks: [
  //     {
  //       stack: "html",
  //       icon: htmlIcon,
  //     },
  //     {
  //       stack: "sass",
  //       icon: scssIcon,
  //     },
  //     {
  //       stack: "parcel",
  //       icon: parcelIcon,
  //     },
  //   ],
  //   features: [
  //     {
  //       item: "Recipe search",
  //     },
  //     {
  //       item: "Upload / Edit recipe to database",
  //     },
  //     {
  //       item: "Add recipe to bookmark",
  //     },
  //   ],
  // },
  // {
  //   name: "Cat Forest",
  //   url: "https://catforest.lionshi.io/",
  //   description: [
  //     "Project Cat Forest is a startup landing page with Cat theme, it is built with HTML5 and Sass. The landing page is composited by several sections, including Hero, Introduction, Features, Business, Prodcuts, Blog and Footer.",
  //     "To learn more about it, please visit the project page below.",
  //   ],
  //   thumbnail: catforestThumbnail,
  //   photo: catforestThumbnail,
  //   type: "Cat Website Landing Page",
  //   tags: ["Landing page"],
  //   githubUrl: "https://github.com/LIONSHI01/cat-forest",
  //   techStacks: [
  //     {
  //       stack: "react",
  //       icon: reactIcon,
  //     },
  //     {
  //       stack: "next",
  //       icon: nextIcon,
  //     },
  //   ],
  //   // features: [
  //   //   {
  //   //     item: "",
  //   //   },
  //   // ],
  // },
  // {
  //   name: "Trillio",
  //   url: "https://trillio.lionshi.io/",
  //   description: [
  //     "Project Trillio is a User Dashboard page of a Travel web application. The page is composited by section Sidebar, Search bar, Gallery, Hotel description, User review, Call-For-Action.",
  //     "To learn more about it, please visit the project page below.",
  //   ],
  //   thumbnail: trillioThumbnail,
  //   photo: trillioThumbnail,
  //   type: "Hotel Website Dashboard",
  //   tags: ["Dashboard"],
  //   githubUrl: "https://github.com/LIONSHI01/trillo-page",
  //   techStacks: [
  //     {
  //       stack: "html",
  //       icon: htmlIcon,
  //     },
  //     {
  //       stack: "sass",
  //       icon: scssIcon,
  //     },
  //   ],
  //   // features: [
  //   //   {
  //   //     item: "",
  //   //   },
  //   // ],
  // },
  // {
  //   name: "Nexter",
  //   url: "https://nexter.lionshi.io/",
  //   description: [
  //     "This is one of Lion Shi's personal projects - Nexter. It is a Landing page of a Real Estate Company. It is composited by section Hero, Story, Features, Gallery and Footer, demonstrating company top sales, company achievements and available properties for sale.",
  //     "To learn more about it, please visit the project page below.",
  //   ],
  //   thumbnail: nexterThumbnail,
  //   photo: nexterThumbnail,
  //   type: "Real Estate Landing Page",
  //   tags: ["Landing page"],
  //   githubUrl: "https://github.com/LIONSHI01/nexter-page",
  //   techStacks: [
  //     {
  //       stack: "html",
  //       icon: htmlIcon,
  //     },
  //     {
  //       stack: "sass",
  //       icon: scssIcon,
  //     },
  //   ],
  //   // features: [
  //   //   {
  //   //     item: "",
  //   //   },
  //   // ],
  // },
];
