import React from "react";

import "./index.scss";

const Button = ({ children, isLink = false, url, otherProps }) => {
  if (isLink === true) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="linkButton"
        {...otherProps}
      >
        {children}
      </a>
    );
  }

  return (
    <button className="button" {...otherProps}>
      {children}
    </button>
  );
};

export default Button;
