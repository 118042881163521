export const sidebarItems = [
  {
    title: "Home",
    hashTag: "#hero",
  },
  {
    title: "About Me",
    hashTag: "#about",
  },
  {
    title: "Portfolios",
    hashTag: "#portfolio",
  },
  {
    title: "Contact",
    hashTag: "#git",
  },
];
