import React from "react";
import { useContext, useState } from "react";

import { SidebarContext } from "../../context/sidebar.context";
import { socialItems } from "../../constants/socialMediaItems";
import "./index.scss";
import { HiMenu } from "../react-icons";
import { ResumePDF } from "../../constants/resourceExport";

const Navbar = () => {
  // Controll NAV BAR sticky
  const [stickyNavbar, setStickyNavbar] = useState(false);

  const setNavbarSticky = () => {
    if (window.scrollY >= 795) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  };

  window.addEventListener("scroll", setNavbarSticky, true);

  // Control SIDEBAR open/close
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);
  const openSidebarHandler = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <nav className={stickyNavbar ? "nav sticky" : "nav"}>
      <ul className="nav__medias">
        {socialItems.map(({ title, icon, url }) => (
          <li className="nav__item" key={title}>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="nav__link"
            >
              {icon}
            </a>
          </li>
        ))}
      </ul>
      <div className="nav__buttons">
        <a href={ResumePDF} className="nav__download" download>
          Download My CV
        </a>
        <div className="nav__menu">
          <button className="nav__menu-item" onClick={openSidebarHandler}>
            <span className="nav__menu-text">menu</span>
            <HiMenu className="nav__menu-icon" />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
