import React, { useState } from "react";

import { FiGithub, ImArrowRight2, AiFillStar } from "../react-icons";
import { Button } from "../index";

import "./index.scss";
const ProjectDetails = ({ project }) => {
  const {
    name,
    description,
    type,
    photo,
    tags,
    url,
    techStacks,
    features,
    githubUrl,
  } = project;

  // STATE
  const [desc, setDesc] = useState([description?.[0].slice(0, 200)]);
  const [isReadMore, setIsReadMore] = useState(false);
  const [featureItems, setFeatureItems] = useState(features?.slice(0, 5));
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  const onReadMoreHandler = () => {
    if (!isReadMore) {
      setDesc(description);
      setIsReadMore(true);
    } else {
      setDesc([description[0].slice(0, 200)]);
      setIsReadMore(false);
    }
  };
  const onShowAllFeatureHandler = () => {
    if (!showAllFeatures) {
      setFeatureItems(features);
      setShowAllFeatures(true);
    } else {
      setFeatureItems(features.slice(0, 5));
      setShowAllFeatures(false);
    }
  };

  return (
    <div className="projectDetails">
      <div className="projectDetails__left">
        <img src={photo} alt={name} className="projectDetails__image" />
        <div className="projectDetails__btns">
          <Button isLink={true} url={githubUrl}>
            <FiGithub size={25} />
            &nbsp; &nbsp;View on Github
          </Button>
          <Button isLink={true} url={url}>
            Visit Website&nbsp;&nbsp;
            <ImArrowRight2 size={25} />
          </Button>
        </div>
      </div>

      <div className="projectDetails__right">
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="projectDetails__title"
        >
          Project : {name} - {type}
        </a>
        <div className="projectDetails__desc-box">
          <h3>Introduction</h3>

          {desc.map((paragraph, i) => (
            <p className="projectDetails__introduction" key={i}>
              {paragraph} {!isReadMore && " ..."}
            </p>
          ))}

          <span
            className="projectDetails__readMore"
            onClick={onReadMoreHandler}
          >
            {isReadMore ? " Show less" : " Read more"}
          </span>

          <div className="projectDetails__tags">
            {tags.map((tag, i) => (
              <Tag key={i} tag={tag} />
            ))}
          </div>
        </div>
        <div className="detailStacks">
          <h3 className="detailStacks__header">Tech stacks applied:</h3>
          <div className="detailStacks__list">
            {techStacks.map(({ stack, icon }) => (
              <div className="detailStacks__stackItem" key={stack}>
                <img
                  className="detailStacks__stacksIcon"
                  src={icon}
                  alt={stack}
                />
                <span className="detailStacks__name">{stack}</span>
              </div>
            ))}
          </div>
        </div>
        {featureItems && (
          <div className="detailFeatures">
            <div className="detailFeatures__header">
              <h3 className="detailFeatures__headerText">App Features :</h3>
              {features.length > 5 && (
                <span
                  className="detailFeatures__showall"
                  onClick={onShowAllFeatureHandler}
                >
                  {showAllFeatures ? " Show less" : " Show all"}
                </span>
              )}
            </div>
            <ul className="detailFeatures__list">
              {featureItems.map((feature) => (
                <li className="detailFeatures__item">
                  <AiFillStar />
                  <span>{feature.item}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const Tag = ({ tag }) => {
  return (
    <div className="detailTag">
      <p className="detailTag__name">{tag}</p>
    </div>
  );
};

export default ProjectDetails;
