import React from "react";
import "./index.scss";
import BreakZone from "../break-zone";

const GetInTouch = () => {
  return (
    <section className="section-git" id="git">
      <BreakZone heading="get in touch with me" color="white" />
      <div className="git container">
        <form className="git__form">
          <input
            type="text"
            className="git__form-input"
            placeholder="Name*"
            required
          />
          <input
            type="email"
            className="git__form-input"
            placeholder="Email*"
            required
          />
          <input
            type="text"
            className="git__form-input"
            placeholder="Website"
          />
          <input
            type="text"
            className="git__form-input"
            placeholder="Subject"
          />
          <textarea
            type="text"
            className="git__form-message"
            placeholder="Message*"
            required
          />
          <button type="submit" className="git__btn">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default GetInTouch;
