import React from "react";
import "./index.scss";

const Overlay = ({ showup, setShowup }) => {
  return (
    <div
      className={showup ? "overlay showup" : "overlay"}
      onClick={() => setShowup(false)}
    />
  );
};

export default Overlay;
