import React, { useState } from "react";

import "./index.scss";
import BreakZone from "../break-zone";
import { ProjectModal, PortfolioCard, ProjectDetails } from "../index";
import { projects } from "../../constants/projectData";

const Portfolio = () => {
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const onSelectProjectHandler = (project) => {
    setSelectedProject(project);
    setShowProjectModal(true);
  };

  return (
    <section className="section-portfolio" id="portfolio">
      {showProjectModal && (
        <ProjectModal
          showup={showProjectModal}
          setShowup={setShowProjectModal}
          project={selectedProject}
        />
      )}
      <BreakZone heading="MY LATEST WORK" color="light" />
      <div className="portfolio">
        {/* <div className="portfolio__cards container">
          {projects.map((project) => (
            <PortfolioCard
              key={project.name}
              project={project}
              onClick={onSelectProjectHandler}
            />
            ))}
        </div> */}
        <div className="portfolio__details-boxs container">
          {projects.map((project) => (
            <ProjectDetails project={project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
